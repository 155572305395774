<template>
  <div class="tag">{{ text }}</div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
};
</script>

<style>
.tag {
  height: var(--tag-height);
  padding: 0 20px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--button-border-radius);
  background: #ccd1df;
  font: var(--tag-font);
  z-index: 99;
}
</style>
